/* eslint-disable @next/next/no-img-element */
import { usePageContext } from "lib/context/page-context";
import { MediaZeroState } from "./MediaZeroState";
import { useCurrentProject } from "lib/context/current-project-context";
import { MediaBlock } from "../types";

export function LogoBlock({ alt }: { alt: MediaBlock["config"]["alt"] }) {
  const project = useCurrentProject();
  const { isEditing } = usePageContext();

  return (
    <>
      {project.logo?.url ? (
        <img src={project.logo.url} alt={alt ?? ""} />
      ) : isEditing ? (
        <MediaZeroState title="Logo" />
      ) : null}
    </>
  );
}
