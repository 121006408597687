import { forwardRef, useEffect, useMemo, useState } from "react";
import { IconOptionCircleChecked, IconOptionCircleUnchecked } from "../icons";
import { cn } from "utils/cn";
import {
  useInterfacesTheme,
  useShadcnComponentsEnabled,
} from "lib/theme/ThemeProvider";
import { InlineSelect as ShadcnInlineSelect } from "@/block-system/brickz/components/ui/InlineSelect";

const LegacyLabel: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"label"> & {
    interfacesTheme?: boolean;
  }
> = ({ interfacesTheme, ...props }) => (
  <label
    {...props}
    className={cn(
      "text-base font-semibold",
      {
        "text-zi-text": !interfacesTheme,
        "text-input-field-foreground": interfacesTheme,
      },
      props.className
    )}
  />
);

const OptionItemContainer: React.FunctionComponent<
  React.ComponentPropsWithRef<"div"> & {
    $isChecked?: boolean;
    interfacesTheme?: boolean;
  }
> = ({ $isChecked, interfacesTheme, ...props }) => (
  <div
    {...props}
    role="option"
    aria-selected={$isChecked}
    className={cn(
      "flex h-[50px] w-full cursor-pointer items-center gap-2.5 border-2 pl-2.5 transition-colors duration-150",
      {
        "rounded-lg border-zi-lightGray bg-zi-superLightGray": !interfacesTheme,
        "rounded-large border-input bg-input-field text-input-field-foreground":
          interfacesTheme,
      },
      {
        "border-zi-primary text-zi-primary": $isChecked && !interfacesTheme,
        "border-primary text-primary": $isChecked && interfacesTheme,
      },
      props.className
    )}
  />
);

const OptionItemSingleSelect = ({
  checked,
  label,
  onClick,
}: {
  checked: boolean;
  label: string;
  onClick?: () => void;
}) => {
  const interfacesTheme = !!useInterfacesTheme();
  return (
    <OptionItemContainer
      $isChecked={checked}
      onClick={onClick}
      interfacesTheme={interfacesTheme}
    >
      {checked ? <IconOptionCircleChecked /> : <IconOptionCircleUnchecked />}
      <LegacyLabel interfacesTheme={interfacesTheme}>{label}</LegacyLabel>
    </OptionItemContainer>
  );
};

type Props = {
  value?: any;
  onChange?: (value?: any) => void;
  options: { label: string; value: any }[];
};

const LegacyInlineSelect = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, options }, ref) => {
    const [selectedValue, setSelectedValue] = useState<
      boolean | string | string[] | undefined
    >(value);

    const handleClick = (option: { label: string; value: string }) => {
      if (value === option.value) {
        setSelectedValue(undefined);
      } else {
        setSelectedValue(option.value);
      }
    };

    const handleChange = useMemo(() => {
      return onChange !== undefined
        ? (value: unknown) => {
            onChange(value);
          }
        : undefined;
    }, [onChange]);

    useEffect(() => {
      handleChange?.(selectedValue);
    }, [selectedValue, handleChange]);

    return (
      <div className="flex flex-col gap-2.5" ref={ref} role="listbox">
        {options.map((option) => (
          <OptionItemSingleSelect
            key={option.value}
            checked={value === option.value}
            label={option.label}
            onClick={() => handleClick(option)}
          />
        ))}
      </div>
    );
  }
);

LegacyInlineSelect.displayName = "InlineSelect";

export const InlineSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const shadcnComponentsEnabled = useShadcnComponentsEnabled();
  if (shadcnComponentsEnabled) {
    return <ShadcnInlineSelect {...props} ref={ref} />;
  }

  return <LegacyInlineSelect {...props} ref={ref} />;
});

InlineSelect.displayName = "InlineSelect";
