import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { ReactNode } from "react";
import { cn } from "utils/cn";

const alertStyles = {
  default: {
    legacy: "border-gray-100 border-l-zi-secondaryText rounded",
    interfaces: "border-border border-l-primary rounded-medium",
  },
  danger: {
    legacy: "border-l-red-500 rounded",
    interfaces: "border-l-destructive rounded-medium",
  },
  warning: {
    legacy: "border-l-yellow-500 rounded",
    interfaces: "border-l-accent rounded-medium",
  },
  success: {
    legacy: "border-l-green-500 rounded",
    interfaces: "border-l-secondary rounded-medium",
  },
};

export function BlockAlert({
  alertStyle = "default",
  title,
  description,
  icon,
  "data-testid": dataTestId,
}: {
  alertStyle?: "default" | "danger" | "warning" | "success";
  title?: ReactNode;
  description: ReactNode;
  icon?: ReactNode;
  "data-testid"?: string;
}) {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      data-testid={dataTestId}
      role="alert"
      className={cn(
        "border border-l-[10px] px-4 py-3 shadow-md",
        { "bg-card": interfacesTheme },
        // prettier-ignore
        alertStyles[alertStyle][
            interfacesTheme
            ?
            "interfaces"
            :
            "legacy"
          ]
      )}
    >
      <div className="flex gap-2 align-top">
        {icon ? <div className="py-1"> {icon} </div> : null}
        <div>
          {title ? (
            <p
              className={cn("font-bold", {
                "text-card-foreground": interfacesTheme,
              })}
            >
              {title}
            </p>
          ) : null}
          <p
            className={cn("text-sm", {
              "text-card-foreground": interfacesTheme,
            })}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}
