import { Badge } from "@/block-system/brickz/components/ui/badge";
import { Button } from "@/block-system/brickz/components/ui/button";
import {
  Popover,
  PopoverTrigger,
} from "@/block-system/brickz/components/ui/popover";
import { cn } from "@/block-system/brickz/lib/utils";
import { Check, ChevronDown, X } from "lucide-react";
import { forwardRef, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { PopoverContent } from "./popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";

export type Props = {
  value?: string | string[];
  placeholder?: string;
  options: { label: string; value: string }[];
  multiSelect?: boolean;
  isErrored?: boolean;
  onChange?: (newValue: string | string[]) => void;
  className?: string;
  isDisabled?: boolean;
};

export const DropdownSelect = forwardRef<HTMLButtonElement, Props>(
  (
    {
      value,
      placeholder,
      options,
      multiSelect,
      isErrored,
      onChange,
      isDisabled,
      className,
    },
    ref
  ) => {
    if (multiSelect) {
      return (
        <DropdownMultiSelect
          options={options}
          value={value as string[] | undefined}
          onChange={(newValues) => {
            onChange?.(newValues);
          }}
          isErrored={isErrored}
          isDisabled={isDisabled}
          className={className}
          placeholder={placeholder}
          ref={ref}
        />
      );
    }

    return (
      <DropdownSingleSelect
        options={options}
        value={value as string | undefined}
        onChange={onChange}
        placeholder={placeholder}
        isErrored={isErrored}
        isDisabled={isDisabled}
        className={className}
        ref={ref}
      />
    );
  }
);

DropdownSelect.displayName = "DropdownSelect";

interface MultiSelectProps {
  options: { label: string; value: string }[];
  value?: string[];
  onChange?: (newValue: string[]) => void;
  className?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  ariaLabel?: string;
  placeholder?: string;
}

export const DropdownMultiSelect = forwardRef<
  HTMLButtonElement,
  MultiSelectProps
>(
  (
    {
      options,
      value = [],
      onChange,
      className,
      isDisabled,
      isErrored,
      ariaLabel,
      placeholder,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const handleUnselect = (item: string) => {
      onChange?.(value.filter((i) => i !== item));
    };

    const handleSelect = (item: string) => {
      const isAddingItem = !value.includes(item);
      if (isAddingItem) {
        onChange?.([...value, item]);
      } else {
        onChange?.(value.filter((i) => i !== item));
      }
    };

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant="outline"
            role="combobox"
            aria-label={ariaLabel}
            disabled={isDisabled}
            aria-expanded={open}
            className={cn(
              "bg-input-field",
              "w-full",
              "border border-solid border-input hover:bg-input-field",
              "data-[errored=true]:border-destructive",
              "px-3 py-2.5",
              {
                /**
                 * Expand the height
                 */
                "h-fit": value.length > 0,
                "cursor-not-allowed opacity-50 [&_*]:cursor-not-allowed":
                  isDisabled,
                "border-destructive": isErrored,
              }
            )}
            onClick={() => setOpen(!open)}
          >
            <DropdownMultiSelectBadgeList
              value={value}
              handleUnselect={handleUnselect}
              placeholder={placeholder}
            />
            <ChevronDown className="ml-auto h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0" align={"start"}>
          <DropdownMultiSelectCommand
            className={className}
            options={options}
            handleSelect={handleSelect}
            value={value}
          />
        </PopoverContent>
      </Popover>
    );
  }
);

function DropdownMultiSelectBadgeList({
  value,
  handleUnselect,
  placeholder,
}: {
  value: string[];
  handleUnselect: (item: string) => void;
  placeholder: string | undefined;
}) {
  const hasItems = value.length > 0;
  const hasPlaceholder = placeholder != null;

  if (!hasItems) {
    if (!hasPlaceholder) {
      return null;
    }

    return (
      <span className="text-sm font-normal text-input-field-foreground/60">
        {placeholder}
      </span>
    );
  }

  return (
    // Rendering an `ul` and `li` inside a `button` would be an invalid HTML.
    // That is why we are using a `div` here.
    <div className="flex flex-wrap gap-1">
      {value.map((item) => {
        return (
          <Badge
            key={item}
            /**
             * To make sure the `button` renders at 40 px height.
             */
            className={"h-[1.125rem]"}
            onClick={() => handleUnselect(item)}
          >
            {item}
            <span
              tabIndex={0}
              className="ml-2 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUnselect(item);
                }
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleUnselect(item);
              }}
            >
              <X className="h-3 w-3 hover:text-foreground" />
            </span>
          </Badge>
        );
      })}
    </div>
  );
}

function DropdownMultiSelectCommand({
  className,
  options,
  handleSelect,
  value,
}: {
  className?: string;
  options: { label: string; value: string }[];
  handleSelect: (item: string) => void;
  value: string[];
}) {
  return (
    <Command className={cn("bg-input-field", className)}>
      <CommandInput placeholder="Search..." />
      <CommandEmpty>No options</CommandEmpty>
      <CommandList>
        <CommandGroup className="max-h-64 overflow-auto">
          {options.map((option) => {
            return (
              <CommandItem
                key={option.value}
                onSelect={() => handleSelect(option.value)}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(option.value) ? "opacity-100" : "opacity-0"
                  )}
                />
                {option.label}
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}

DropdownMultiSelect.displayName = "DropdownMultiSelect";

type SingleSelectProps = {
  value?: string;

  onChange?: (newValue: string) => void;
  options: { label: string; value: string }[];

  placeholder?: string;
  isErrored?: boolean;

  size?: "small" | "medium";
  ariaLabel?: string;

  isDisabled?: boolean;
  className?: string;
};

const DropdownSingleSelect = forwardRef<HTMLButtonElement, SingleSelectProps>(
  (
    { value, onChange, options, placeholder, isErrored, isDisabled, className },
    ref
  ) => {
    return (
      <Select value={value} onValueChange={onChange}>
        <SelectTrigger
          ref={ref}
          disabled={isDisabled}
          data-errored={isErrored}
          className={cn(
            "data-[errored=true]:border-destructive",
            "h-10",
            "px-3 py-2.5",
            {
              "disabled:cursor-not-allowed": isDisabled,
              "text-input-field-foreground/60": value == null,
            },
            className
          )}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options.map((option) => {
            return (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    );
  }
);

DropdownSingleSelect.displayName = "DropdownSingleSelect";
