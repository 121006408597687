import { SuccessNotification } from "block-system/components/Notification";
import { NotificationAction } from "block-system/types";
import toast from "react-hot-toast";

export function showSuccessNotification(
  notificationAction: NotificationAction
) {
  toast.custom(
    (t) => (
      <SuccessNotification
        toast={t}
        message={notificationAction.config.message}
        handleClose={toast.dismiss}
      />
    ),
    { position: notificationAction.config.position ?? "top-center" }
  );
}
