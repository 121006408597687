import { truncate } from "lodash";
import { cn } from "@/block-system/brickz/lib/utils";

type props = {
  value: string;
  className?: string;
};

export const AIFormula = ({ value, className }: props) => {
  return (
    <div
      className={cn(
        "border-1 min-h-28 rounded-medium border border-input bg-input-field text-sm text-input-field-foreground/60",
        "px-3 py-2.5",
        className
      )}
    >
      {truncate(value, { length: 250 })}
    </div>
  );
};
