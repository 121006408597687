import { TextInput as ShadcnTextInput } from "../../brickz/components/ui/TextInput";
import {
  useInterfacesTheme,
  useShadcnComponentsEnabled,
} from "lib/theme/ThemeProvider";
import { ChangeEvent, forwardRef, ReactNode, useMemo } from "react";
import { cn } from "utils/cn";

const LegacyIconWrapper: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div">
> = (props) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn("flex h-full grow-0 items-center justify-center", {
        "text-zi-placeholder": !interfacesTheme,
        "text-input-field-foreground/60": interfacesTheme,
      })}
    />
  );
};

type InputProps = Omit<
  React.ComponentPropsWithoutRef<"input">,
  "onChange" | "size" | "type" | "value"
>;

export type Props = InputProps & {
  value?: string;
  onChange?: (value: string) => void;
  type?: "email" | "password" | "search" | "tel" | "text";
  size?: "small" | "medium";
  isErrored?: boolean;
  renderIconBefore?: () => ReactNode;
  renderIconAfter?: () => ReactNode;
  isDisabled?: boolean;
  isRequired?: boolean;
  ariaDescribedBy?: string;
};

export const LegacyTextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      isErrored,
      size,
      isDisabled,
      isRequired,
      ariaDescribedBy,
      renderIconAfter,
      renderIconBefore,
      type = "text",
      ...props
    },
    ref
  ) => {
    const handleChange = useMemo(() => {
      return onChange !== undefined
        ? (e: ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);
          }
        : undefined;
    }, [onChange]);

    const interfacesTheme = useInterfacesTheme();

    return (
      <div
        data-errored={isErrored}
        data-size={size}
        className={cn(
          "flex h-[50px] flex-row flex-nowrap items-center gap-x-2.5 overflow-hidden border-2 px-4 py-0 text-[17px] transition-colors duration-150 data-[size=small]:h-10 data-[size=small]:text-[15px]",
          {
            "rounded-lg border-zi-lightGray bg-zi-superLightGray text-zi-text focus-within:border-zi-primary data-[errored=true]:border-zi-formError":
              !interfacesTheme,
            "rounded-large border-input bg-input-field text-input-field-foreground focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background data-[errored=true]:border-destructive":
              interfacesTheme,
          }
        )}
      >
        {renderIconBefore ? (
          <LegacyIconWrapper>{renderIconBefore()}</LegacyIconWrapper>
        ) : null}
        <input
          ref={ref}
          onChange={handleChange}
          aria-describedby={ariaDescribedBy}
          {...props}
          type={type}
          required={isRequired ?? false}
          disabled={props.disabled ?? isDisabled ?? false}
          value={props.value ?? ""}
          className={cn(
            "block h-full w-0 grow border-none bg-transparent px-0 py-4 text-inherit outline-none",
            {
              "placeholder:text-zi-placeholder": !interfacesTheme,
              "placeholder:text-input-field-foreground/60": interfacesTheme,
            }
          )}
        />
        {renderIconAfter ? (
          <LegacyIconWrapper>{renderIconAfter()}</LegacyIconWrapper>
        ) : null}
      </div>
    );
  }
);

export const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const shadcnComponentsEnabled = useShadcnComponentsEnabled();
  if (shadcnComponentsEnabled) {
    return <ShadcnTextInput {...props} ref={ref} />;
  }

  return <LegacyTextInput {...props} ref={ref} />;
});

TextInput.displayName = "TextInput";
LegacyTextInput.displayName = "LegacyTextInput";
