import React, { useId } from "react";
import { Icon } from "@zapier/design-system";

import {
  useInterfacesTheme,
  useShadcnComponentsEnabled,
} from "@/lib/theme/ThemeProvider";
import {
  Field as ShadcnField,
  Props as ShadcnFieldProps,
  ConditionallyRenderedFieldLabel as ShadcnConditionallyRenderedFieldLabel,
  ReadOnlyFieldLabel as ShadcnReadOnlyFieldLabel,
} from "@/block-system/brickz/components/ui/Field";
import { cn } from "@/utils/cn";
import { ConditionalRenderBadge } from "@/block-system/components/ConditionalRenderBadge";

type RenderInputOptions = {
  "aria-describedby"?: string;
  id: string;
  isDisabled: boolean;
  isErrored: boolean;
  isRequired: boolean;
};

export type RenderLabelProps = {
  htmlFor: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  isRequired?: boolean;
  label: string;
  requiredText?: React.ReactNode;
};

type LegacyFieldProps = {
  /**
   * Optional error message for the field. If it exists, the field will be
   * considered to have an error.
   */
  error?: string;
  /**
   * The number of help text lines to show (defaults to 1). Any extra text will be
   * hidden behind a "more" button.
   */
  helpTextLineClamp?: number;
  /**
   * `id` for the input field. If this isn't supplied, then one will be generated
   * to properly link the `label` to the input.
   */
  inputId?: string;
  /**
   * Indicates whether the field is disabled.
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the field is required.
   */
  isRequired?: boolean;
  /**
   * Text for the `label` element for this field.
   */
  label: string;
  /**
   * Function that returns the help text to be rendered beneath the input.
   */
  renderHelpText?: () => React.ReactNode;
  /**
   * Function that returns the rendered input field.
   */
  renderInput: (opts: RenderInputOptions) => React.ReactNode;
  /**
   * Function that returns the label to be rendered.
   */
  renderLabel?: (labelProps: RenderLabelProps) => React.ReactNode;
  /**
   * Optional text to render inside the required label.
   */
  requiredText?: React.ReactNode;
  /**
   * Optional boolean to not display the field label.
   */
  hideLabel?: boolean;
};

export type FieldProps = LegacyFieldProps | ShadcnFieldProps;

const defaultLegacyFieldProps = {
  helpTextLineClamp: 1,
};

/**
 * Wraps an input field and renders a label and optional help text.
 */
export function LegacyField(_props: LegacyFieldProps) {
  const props = {
    ...defaultLegacyFieldProps,
    ..._props,
  };

  const customId = useId();
  const id = props.inputId ?? customId;

  const describedById = `${id}-description`;
  const helpText = props.renderHelpText && props.renderHelpText();

  return (
    <div className="flex flex-col gap-y-2.5">
      {!props.hideLabel && (
        <div>
          {props.renderLabel ? (
            props.renderLabel({
              htmlFor: id,
              isDisabled: props.isDisabled,
              isErrored: !!props.error,
              isRequired: props.isRequired,
              label: props.label,
              requiredText: props.requiredText,
            })
          ) : (
            <LegacyFieldLabel
              alignItems="start"
              htmlFor={id}
              isDisabled={props.isDisabled}
              isErrored={!!props.error}
              isRequired={props.isRequired}
              requiredText={props.requiredText}
              size="small"
            >
              {props.error ? (
                <Icon isBlock={true} name="alertTriangle" size={18} />
              ) : null}
              <span>{props.label}</span>
            </LegacyFieldLabel>
          )}
        </div>
      )}
      {props.renderInput({
        "aria-describedby": helpText || props.error ? describedById : undefined,
        id,
        isDisabled: !!props.isDisabled,
        isErrored: !!props.error,
        isRequired: !!props.isRequired,
      })}
      {props.error ? <LegacyFieldError>{props.error}</LegacyFieldError> : null}
      {helpText ? <LegacyHelpText>{helpText}</LegacyHelpText> : null}
    </div>
  );
}

export function Field(props: FieldProps) {
  const shadcnEnabled = useShadcnComponentsEnabled();
  if (shadcnEnabled) {
    return <ShadcnField {...props} />;
  }

  return <LegacyField {...props} />;
}

const LegacyHelpText: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div">
> = (props) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "text-sm [&_a:visited]:text-inherit [&_a]:underline",
        {
          "text-zi-secondaryText": !interfacesTheme,
          "text-card-foreground-subtle": interfacesTheme,
        },
        props.className
      )}
    />
  );
};

type LegacyFieldLabelProps = {
  alignItems?: "center" | "start";
  justifyContent?: "start" | "stretch";
  children: React.ReactNode;
  htmlFor?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  isRequired?: boolean;
  isSelected?: boolean;
  requiredText?: React.ReactNode;
  size?: "small" | "medium";
};

const legacyFieldLabelDefaultProps = {
  alignItems: "center",
  justifyContent: "start",
  size: "medium",
};

/**
 * `FormLabel` associates text to input fields.
 */
const LegacyFieldLabel = (_props: LegacyFieldLabelProps) => {
  const props = {
    ...legacyFieldLabelDefaultProps,
    ..._props,
  };

  const interfacesTheme = useInterfacesTheme();

  return (
    <label
      className={cn(
        "grid auto-cols-auto grid-flow-col items-start justify-start gap-[5px] text-base font-semibold",
        {
          "text-zi-text [&[data-state~='errored']]:text-zi-formError":
            !interfacesTheme,
          "text-card-foreground [&[data-state~='errored']]:text-destructive":
            interfacesTheme,
        }
      )}
      data-align-items={props.alignItems}
      data-justify-content={props.justifyContent}
      data-size={props.size}
      data-state={
        [
          props.isSelected && "selected",
          props.isErrored && "errored",
          props.isDisabled && "disabled",
        ]
          .filter(Boolean)
          .join(" ") || undefined
      }
      htmlFor={props.htmlFor}
    >
      {props.children}
      {props.isRequired ? (
        props.requiredText ? (
          <LegacyFieldLabelRequiredText>
            {props.requiredText}
          </LegacyFieldLabelRequiredText>
        ) : (
          <LegacyFieldLabelRequiredText />
        )
      ) : null}
    </label>
  );
};

export function LegacyFieldError(props: React.ComponentPropsWithoutRef<"div">) {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "font-sans text-sm",
        {
          "text-destructive": interfacesTheme,
          "text-zi-formError": !interfacesTheme,
        },
        props.className
      )}
    />
  );
}

/**
 * `FormLabelRequiredText` indicates whether a field is required.
 * Should always be a descendant of a `Label`.
 */
export const LegacyFieldLabelRequiredText = ({
  children = "required",
}: {
  children?: React.ReactNode;
}) => {
  const interfacesTheme = useInterfacesTheme();

  return (
    // `aria-hidden` to prevent it from being read redundantly.
    // The associated `input` field should indicate whether it's
    // required to screenreaders.
    <span
      className={cn(
        "ml-0.5 font-normal before:content-['('] after:content-[')']",
        {
          "text-zi-secondaryText": !interfacesTheme,
          "text-card-foreground-subtle": interfacesTheme,
        }
      )}
      aria-hidden="true"
    >
      {children}
    </span>
  );
};

function LegacyConditionallyRenderedLabel({
  label,
  ...labelProps
}: RenderLabelProps) {
  return (
    <LegacyFieldLabel {...labelProps}>
      <span>{label}</span>
      <ConditionalRenderBadge />
    </LegacyFieldLabel>
  );
}

export function ConditionallyRenderedLabel(props: RenderLabelProps) {
  const shadcnEnabled = useShadcnComponentsEnabled();
  if (shadcnEnabled) {
    return <ShadcnConditionallyRenderedFieldLabel {...props} />;
  }

  return <LegacyConditionallyRenderedLabel {...props} />;
}

function LegacyReadOnlyLabel({ label, ...labelProps }: RenderLabelProps) {
  return (
    <span className="flex items-center">
      <LegacyFieldLabel {...labelProps}>
        <span>{label}</span>
      </LegacyFieldLabel>
      <div className={"group relative cursor-not-allowed px-1"}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          height="18"
          width="18"
          color="neutral600"
        >
          <path
            fill="#95928E"
            d="M17 8V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H4v10h2v-8h12v10H4v2h16V8h-3Zm-2 0H9V6c0-1.65 1.35-3 3-3s3 1.35 3 3v2Z"
          ></path>
          <path
            fill="#95928E"
            d="M12 17.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
          ></path>
        </svg>
      </div>
      <span className="text-sm">(read only)</span>
    </span>
  );
}

export function ReadOnlyLabel(props: RenderLabelProps) {
  const shadcnEnabled = useShadcnComponentsEnabled();
  if (shadcnEnabled) {
    return <ShadcnReadOnlyFieldLabel {...props} />;
  }

  return <LegacyReadOnlyLabel {...props} />;
}
