import React, { forwardRef, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { MdOutlineDateRange } from "react-icons/md";
import formatDate from "date-fns/format";
import {
  useInterfacesTheme,
  useShadcnComponentsEnabled,
} from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";
import { DatePicker as ShadcnDatePicker } from "@/block-system/brickz/components/ui/DatePicker";

const LegacyDatePickerCustomInput = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & {
    isDisabled?: boolean;
    isErrored?: boolean;
    placeholder?: string;
    active?: boolean;
    date?: Date;
    includeTime: boolean;
  }
>(
  (
    {
      date,
      includeTime,
      onClick,
      isDisabled,
      isErrored,
      placeholder,
      active,
      id,
    },
    ref
  ) => {
    const interfacesTheme = useInterfacesTheme();

    return (
      <div
        data-disabled={isDisabled}
        data-errored={isErrored}
        data-active={active}
        className={cn(
          "flex h-12 w-full cursor-pointer items-center gap-2.5 border-2 pl-2.5 text-base data-[disabled=true]:cursor-default",
          {
            "rounded-lg border-zi-lightGray bg-zi-superLightGray text-zi-text data-[active=true]:border-zi-primary data-[errored=true]:border-zi-formError":
              !interfacesTheme,
            "rounded-large border-input bg-input-field text-input-field-foreground focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background data-[errored=true]:border-destructive":
              interfacesTheme,
          }
        )}
        onClick={onClick}
      >
        <div className="text-muted-foreground">
          <MdOutlineDateRange size={20} />
        </div>
        <input
          style={{ all: "unset", flexGrow: 1 }}
          id={id}
          ref={ref}
          readOnly
          value={
            date
              ? `${formatDate(date, "MMM d, yyyy")} ${
                  includeTime ? formatDate(date, "hh:mm a") : ""
                }`
              : ""
          }
          placeholder={placeholder}
          onClick={onClick}
        />
      </div>
    );
  }
);

LegacyDatePickerCustomInput.displayName = "DatePickerCustomInput";

type LegacyDatePickerProps = {
  dateFormat: string;
  includeTime: boolean;
  defaultToNow: boolean;
  value?: Date;
  onChange?: (value: Date | null) => void;
  placeholder?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  id: string;
  name: string;
};

const LegacyDatePicker = forwardRef<HTMLInputElement, LegacyDatePickerProps>(
  (
    {
      value,
      onChange,
      dateFormat,
      includeTime,
      placeholder,
      isDisabled,
      isErrored,
      id,
    },
    ref
  ) => {
    const [active, setActive] = useState(false);

    const handleChange = useMemo(() => {
      return (value: Date | null) => {
        if (onChange) {
          onChange(value);
        }
      };
    }, [onChange]);

    const datePickerRef = React.createRef<ReactDatePicker>();

    const interfacesTheme = useInterfacesTheme();

    return (
      <div
        className={cn("custom-datepicker", {
          "interfaces-theme": interfacesTheme,
        })}
      >
        <ReactDatePicker
          ref={datePickerRef}
          wrapperClassName="DatePicker w-[100%]"
          popperClassName="DatePicker-Popper"
          dateFormat={includeTime ? dateFormat + " HH:mm" : dateFormat}
          placeholderText={placeholder}
          selected={value}
          onChange={handleChange}
          calendarContainer={(props) => {
            return <div {...props} data-testid="react-calendar-container" />;
          }}
          /**
           * The `id` we set here gets passed to the `DatePickerCustomInput`
           * by the `ReactDatePicker` library.
           */
          id={id}
          isClearable
          customInput={
            <LegacyDatePickerCustomInput
              ref={ref}
              isDisabled={isDisabled}
              isErrored={isErrored}
              placeholder={placeholder}
              active={active}
              date={value}
              includeTime={includeTime}
            />
          }
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          showTimeInput={includeTime}
          shouldCloseOnSelect={false}
          onCalendarOpen={() => setActive(true)}
          onCalendarClose={() => setActive(false)}
          disabled={isDisabled}
        />
        {/* We could not close the date-picker in e2es.
        We have created this invisible button to click on it when we are done setting the value on the date-picker. */}
        <button
          data-testid="close-date-picker"
          type="button"
          className={"sr-only"}
          onClick={() => {
            datePickerRef.current?.setOpen(false);
          }}
        >
          Close date picker
        </button>
      </div>
    );
  }
);

LegacyDatePicker.displayName = "LegacyDatePicker";

export const DatePicker = forwardRef<HTMLInputElement, LegacyDatePickerProps>(
  (props, ref) => {
    const shadcnComponentsEnabled = useShadcnComponentsEnabled();
    return shadcnComponentsEnabled ? (
      <ShadcnDatePicker {...props} ref={ref} />
    ) : (
      <LegacyDatePicker {...props} ref={ref} />
    );
  }
);

DatePicker.displayName = "DatePicker";
