import { CountryCode } from "libphonenumber-js/types";
import { forwardRef } from "react";
import PhoneInput, { Value } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { cn } from "@/block-system/brickz/lib/utils";
import styles from "./PhoneNumberInput.styles.module.css";

export type Props = {
  value?: string;
  onChange?: (value: Value | undefined) => void;
  defaultCountry: CountryCode;
  placeholder?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  size?: "medium" | "small";
  id: string;
  name: string;
};

export const PhoneNumberInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      onChange,
      value,
      defaultCountry,
      placeholder,
      isDisabled = false,
      isErrored = false,
      size = "medium",
      id,
      name,
    },
    ref
  ) => {
    // const inputDisabled = disabled ?? isDisabled;

    return (
      <div
        ref={ref}
        className={cn(
          "flex flex-row flex-nowrap gap-x-2.5",
          "border-1 border border-input",
          "px-3 py-2.5",
          "text-sm",
          "transition-colors duration-150",
          "h-10 min-h-10",
          "overflow-hidden",
          "rounded-medium",
          "bg-input-field",
          "text-input-field-foreground",
          "focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background",
          {
            "h-9 min-h-9 py-1.5 text-xs": size === "small",
            "border-destructive": isErrored,
            "cursor-not-allowed opacity-50 [&_*]:cursor-not-allowed":
              isDisabled,
          },
          styles.container
        )}
      >
        <PhoneInput
          numberInputProps={{
            id,
            name,
            className: cn({
              "pl-2": size === "small",
            }),
          }}
          flags={flags}
          placeholder={placeholder}
          defaultCountry={defaultCountry}
          value={value}
          onChange={(value) => {
            onChange?.(value);
          }}
          disabled={isDisabled}
        />
      </div>
    );
  }
);

PhoneNumberInput.displayName = "PhoneNumberInput";
