import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import React from "react";
import { cn } from "utils/cn";

type DivProps = React.ComponentPropsWithoutRef<"div"> & { as?: never };
type FieldsetProps = React.ComponentPropsWithoutRef<"fieldset"> & {
  as: "fieldset";
};

type Props = DivProps | FieldsetProps;

export const FormContainer = ({ as, className, ...props }: Props) => {
  const interfacesTheme = useInterfacesTheme();
  const classes = cn(
    "m-0 flex flex-col flex-nowrap gap-y-[30px] border-none p-[30px] shadow-subtle",
    {
      "rounded-xl bg-zi-white": !interfacesTheme,
      "rounded-large bg-card": interfacesTheme,
    },
    className
  );

  if (as) {
    return (
      <fieldset
        {...(props as React.ComponentPropsWithoutRef<"fieldset">)}
        className={classes}
      />
    );
  }

  return (
    <div
      {...(props as React.ComponentPropsWithoutRef<"div">)}
      className={classes}
    />
  );
};
