import { forwardRef } from "react";
import { TextInput } from "./TextInput";
import { UrlInput as ShadcnUrlInput } from "@/block-system/brickz/components/ui/UrlInput";
import { useShadcnComponentsEnabled } from "@/lib/theme/ThemeProvider";
import { TextInputProps } from "@/block-system/brickz/components/ui/TextInput";

type UrlInputProps = Omit<TextInputProps, "type" | "inputMode">;

export const LegacyUrlInput = forwardRef<HTMLInputElement, UrlInputProps>(
  ({ placeholder, ...props }, ref) => {
    return (
      <TextInput
        ref={ref}
        placeholder={placeholder}
        inputMode="url"
        {...props}
      />
    );
  }
);

LegacyUrlInput.displayName = "LegacyUrlInput";

export const UrlInput = forwardRef<HTMLInputElement, UrlInputProps>(
  (props, ref) => {
    const shadcnComponentsEnabled = useShadcnComponentsEnabled();
    if (shadcnComponentsEnabled) {
      return <ShadcnUrlInput {...props} ref={ref} />;
    }

    return <LegacyUrlInput {...props} ref={ref} />;
  }
);

UrlInput.displayName = "UrlInput";
