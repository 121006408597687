import { AddBlock } from "components/PageEditor/types";
import React, { ReactNode } from "react";
import {
  BlockId,
  FocusState,
  MutateBlocks,
  SetFocusState,
} from "block-system/types";
import { Project, Page } from "utils/trpc";
import { AccountId } from "components/Projects/types";
import { useCurrentProject } from "./current-project-context";

export type PageContextValue = {
  isEditing: boolean;
  isEmbedded: boolean;
  isThumbnail?: boolean;
  accountId?: AccountId;
  projectId?: string;
  pageId?: Page["id"];
  focusState?: FocusState;
  setFocusState?: SetFocusState;
  addBlock?: AddBlock;
  mutate?: MutateBlocks;
  freshBlockId?: BlockId | null;
  allPages?: Record<
    string,
    { id: Page["id"]; isHomepage: boolean; title: string; url: string }
  >;
  globalNavigation?: Project["globalNavigation"];
  includeLogo?: Project["includeLogo"];
  navigationLinks?: Project["navigationLinks"];
  projectName?: Project["name"];
  projectPublicName?: Project["publicName"];
  projectSlug?: Project["slug"];
  navigationEnabled?: Page["navigationEnabled"];
  logo?: Project["logo"];
  paidFeatureAccess?: Project["paidFeatureAccess"];
  creator?: {
    id: string;
    zapierId: number;
    isStaff: boolean;
  };
};

const defaultValue: PageContextValue = {
  isEditing: false,
  isEmbedded: false,
};

export const PageContext = React.createContext<PageContextValue>(defaultValue);

export function usePageContext() {
  return React.useContext(PageContext);
}

export type ProviderProps = Partial<PageContextValue> & { children: ReactNode };

export const PageContextProvider = React.memo((props: ProviderProps) => {
  const existingContext = usePageContext();

  const { children, ...newContext } = props;

  const value = {
    ...defaultValue,
    ...existingContext,
    ...newContext,
  };

  const project = useCurrentProject();

  value.creator = project.creator ?? value.creator;

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
});

PageContextProvider.displayName = "PageContextProvider";
