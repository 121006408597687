import { isNull } from "lodash";
import React from "react";
import { Project } from "utils/trpc";

const CurrentProjectContext = React.createContext<Project | null>(null);

export function useCurrentProject() {
  const project = React.useContext(CurrentProjectContext);

  if (isNull(project)) {
    throw new Error(
      "useCurrentProject must be used within a CurrentProjectContext.Provider"
    );
  }

  return project;
}

export function CurrentProjectProvider({
  children,
  project,
}: {
  children: React.ReactNode;
  project: Project;
}) {
  return (
    <CurrentProjectContext.Provider value={project}>
      {children}
    </CurrentProjectContext.Provider>
  );
}
