import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";

export function MediaZeroState({
  title,
  description,
}: {
  title: string;
  description?: string;
}) {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      className={cn("flex h-full w-full items-center justify-center gap-y-5", {
        "bg-muted": interfacesTheme,
        "bg-zi-gray": !interfacesTheme,
      })}
    >
      <p className="text-[34px] font-semibold leading-10 tracking-[1px]">
        {title}
      </p>
      <p className="text-[18px] font-normal leading-[31px] empty:hidden">
        {description}
      </p>
    </div>
  );
}
