import { forwardRef } from "react";
import { InlineSelect } from "..";

type Props = Omit<
  React.ComponentPropsWithoutRef<typeof InlineSelect>,
  "options" | "value"
> & {
  value?: boolean;
};

export const YesNoInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <InlineSelect
      ref={ref}
      {...props}
      options={[
        { label: "Yes", value: true },
        { label: "No", value: false },
      ]}
    />
  );
});

YesNoInput.displayName = "YesNoInput";
