import React, { forwardRef, useState } from "react";
import { truncate } from "lodash";
import { cn } from "utils/cn";
import { isValidLinkedRecordFormat } from "block-system/blocks/Table/Block/utils";
import { LinkedRecordsInput } from "@/block-system/brickz/components/ui/LinkedRecordsInput";
import { useShadcnComponentsEnabled } from "@/lib/theme/ThemeProvider";

type Props = {
  value:
    | { source: string; label: string }
    | { source: string; label: string }[]
    | undefined;
  className?: string;
};

export const LegacyLinkedRecordFields: React.FunctionComponent<Props> = ({
  value,
  className,
}) => {
  // This number is arbitrary and can be changed. It just satisfies the design
  const MAXDISPLAYCOUNT = 5;
  const records = Array.isArray(value) ? value : [value];
  const [displayAll, setDisplayAll] = useState(false);
  const recordsToDisplay = displayAll
    ? records
    : records.slice(0, MAXDISPLAYCOUNT);

  const handleSeeMoreClick = () => {
    setDisplayAll(!displayAll);
  };

  if (!isValidLinkedRecordFormat(records)) {
    return (
      <div
        className={cn(
          "color-container min-h-28 rounded border bg-gray-50 px-3 py-4",
          className
        )}
      />
    );
  }

  return (
    <div
      className={cn(
        "color-container min-h-28 rounded border bg-gray-50 px-3 py-4",
        className
      )}
    >
      <ul>
        {recordsToDisplay.map((record, index) => (
          <li
            key={index}
            className="mb-2 rounded border bg-zinc-50 px-3 py-1 text-sm text-gray-700"
            style={{ width: "fit-content" }}
          >
            {truncate(record?.label, { length: 100 })}
          </li>
        ))}
      </ul>
      {records.length > MAXDISPLAYCOUNT && (
        <button
          aria-label={displayAll ? "See less" : "See more"}
          type="button"
          className="mt-2 cursor-pointer text-sm text-blue-600 underline"
          onClick={handleSeeMoreClick}
        >
          {displayAll ? "See less" : "See more"}
        </button>
      )}
    </div>
  );
};

export const LinkedRecordFields = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const shadcnComponentsEnabled = useShadcnComponentsEnabled();
    if (shadcnComponentsEnabled) {
      return <LinkedRecordsInput {...props} />;
    }

    return <LinkedRecordsInput {...props} ref={ref} />;
  }
);

LinkedRecordFields.displayName = "LinkedRecordFields";
