import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { Fragment } from "react";
import { cn } from "utils/cn";

type Props = {
  title?: string;
  children: React.ReactNode;
};

export function Links({ title, children }: Props) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <div>
      {title ? (
        <Fragment>
          <div
            className={cn([
              "text-3xl font-extrabold",
              {
                "text-zi-black": !interfacesTheme,
                "text-foreground": interfacesTheme,
              },
            ])}
          >
            {title}
          </div>
          <div className="h-5" />
        </Fragment>
      ) : null}
      <div className="ml-[-10px] grid grid-cols-[repeat(auto-fit,_346px)] justify-center gap-8">
        {children}
      </div>
    </div>
  );
}
