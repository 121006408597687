import { Icon, Spacer, TagLabel } from "@zapier/design-system";
import { styled } from "lib/theme";

const ConditionalRenderIconWrapper = styled.span`
  transform: rotate(180deg) translateY(3px);
`;

export function ConditionalRenderBadge() {
  return (
    <TagLabel color="neutral400">
      <ConditionalRenderIconWrapper>
        <Icon color="neutral800" name="arrowBranch" size={16} />
      </ConditionalRenderIconWrapper>
      <Spacer width={2} />
      Conditionally rendered
    </TagLabel>
  );
}
