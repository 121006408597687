import { Spinner } from "block-system/components/Spinner";
import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "utils/cn";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

export type ButtonColor = "primary" | "secondary" | "danger";

// NOTE Any changes here must be reflected bellow in the v2ButtonVariants
const buttonVariantsV1 = cva(
  "relative w-full cursor-pointer whitespace-nowrap rounded-lg border border-2 border-solid px-[15px] py-0 transition-all duration-150 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-zi-white active:brightness-100 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-200 disabled:text-gray-400 [&:not(:disabled):hover]:brightness-110",
  {
    variants: {
      color: {
        primary:
          "border-zi-primary bg-zi-primary font-semibold text-zi-white focus-visible:ring-zi-primary",
        secondary:
          "border-zi-primary bg-zi-white font-medium text-zi-primary focus-visible:ring-zi-primary",
        danger:
          "border-zi-danger bg-zi-danger font-semibold text-zi-white focus-visible:ring-zi-danger",
      },
      size: {
        small: "h-10 text-[15px]",
        medium: "h-[50px] text-[17px]",
      },
    },
    defaultVariants: {
      color: "primary",
      size: "medium",
    },
  }
);

// NOTE Any changes here must be reflected above in the v1ButtonVariants
const buttonVariantsV2: typeof buttonVariantsV1 = cva(
  [
    "relative w-full cursor-pointer whitespace-nowrap rounded-small px-[15px] py-0 transition-all duration-150",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-background",
    "focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
  ],
  {
    variants: {
      color: {
        primary:
          "bg-primary font-semibold text-primary-foreground [&:not(:disabled):hover]:bg-primary/80",
        secondary:
          "bg-secondary font-medium text-secondary-foreground [&:not(:disabled):hover]:bg-secondary/80",
        danger:
          "bg-destructive font-semibold text-destructive-foreground [&:not(:disabled):hover]:bg-destructive/80",
      },
      size: {
        small: "h-10 text-[15px]",
        medium: "h-[50px] text-[17px]",
      },
    },
    defaultVariants: {
      color: "primary",
      size: "medium",
    },
  }
);

export interface Props
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color">,
    VariantProps<typeof buttonVariantsV1> {
  isLoading?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  function Button(
    { className, color, size, isLoading, children, ...props },
    ref
  ) {
    const interfacesTheme = useInterfacesTheme();

    const buttonVariants = interfacesTheme
      ? buttonVariantsV2
      : buttonVariantsV1;

    return (
      <button
        className={cn(buttonVariants({ className, color, size }))}
        disabled={isLoading ?? props.disabled}
        ref={ref}
        {...props}
      >
        <span style={{ opacity: isLoading ? 0 : 1 }}>{children}</span>
        {isLoading ? (
          <span className="absolute inset-0 flex items-center justify-center">
            <Spinner className="text-current" />
          </span>
        ) : null}
      </button>
    );
  }
);
