import {
  BlockWrapper,
  Checkbox,
  CurrencyInput,
  DatePicker,
  DropdownSelect,
  DynamicDropdownSelect,
  Field,
  FileUpload,
  PhoneNumberInput,
  TextArea,
  TextInput,
  UrlInput,
  YesNoInput,
} from "block-system/components";
import { AIFormula } from "block-system/components/forms/AIFormula";
import {
  ConditionallyRenderedLabel,
  FieldProps,
  ReadOnlyLabel,
} from "block-system/components/forms/Field";
import { LinkedRecordFields } from "block-system/components/forms/LinkedRecordFields";
import { Spinner } from "block-system/components/Spinner";
import { BlockId, ContentBlock } from "block-system/types";
import { useCurrentProject } from "lib/context/current-project-context";
import { usePageContext } from "lib/context/page-context";
import { useContentDatasource } from "lib/hooks/useContentDatasource";
import { useFeatureAccessCheck } from "lib/hooks/useFeatureAccessCheck";
import { parseHandlebars } from "lib/utils/parseHandlebars";
import type { CountryCode } from "libphonenumber-js/types";
import { trim } from "lodash";
import { ReactNode, Suspense, useCallback } from "react";
import {
  Controller,
  useFormContext,
  type ControllerRenderProps,
  type FieldValues,
} from "react-hook-form";
import ReactMarkdown from "react-markdown";
import { isConditionallyRendered } from "../lib/conditional-logic/conditional-logic";
import { DateFormatOptions } from "../schema";
import type {
  FieldBlock as FieldBlockType,
  SupportedCurrencies,
} from "../types";
import { isDynamicVariable } from "../utils";
import { buildValidationRules } from "./utils";

type RenderInputProps = Parameters<FieldProps["renderInput"]>[0];

const MEGABYTE = 1024 * 1024;

export const FREE_FILE_SIZE = 5 * MEGABYTE;
export const PREMIUM_FILE_SIZE = 10 * MEGABYTE;
export const ADVANCED_FILE_SIZE = 25 * MEGABYTE;

export function FieldBlock(props: {
  block: FieldBlockType;
  blocks: ContentBlock[];
  blockId: BlockId;
}) {
  const { config } = props.block;
  const { data: datasource } = useContentDatasource();
  const { creator, paidFeatureAccess } = useCurrentProject();

  let maxFileSize = FREE_FILE_SIZE;

  const allowAdvancedFileSize = useFeatureAccessCheck("fileSizeLimit25mb", {
    ...creator,
    paidFeatureAccess,
  });
  const allowPremiumFileSize = useFeatureAccessCheck("fileSizeLimit10mb", {
    ...creator,
    paidFeatureAccess,
  });

  if (allowAdvancedFileSize) {
    maxFileSize = ADVANCED_FILE_SIZE;
  } else if (allowPremiumFileSize) {
    maxFileSize = PREMIUM_FILE_SIZE;
  } else {
    maxFileSize = FREE_FILE_SIZE;
  }

  const { isEditing } = usePageContext();

  const name = config.name;

  const validationRules = buildValidationRules(config);

  const {
    formState: { errors, isSubmitting },
  } = useFormContext();

  const renderMarkdown = useCallback(
    (content: string) => {
      if (!isEditing) content = parseHandlebars(content, datasource);

      return (
        <ReactMarkdown
          allowedElements={[
            "a",
            "p",
            "strong",
            "em",
            "code",
            "pre",
            "li",
            "ol",
            "ul",
          ]}
          linkTarget="_blank"
        >
          {content}
        </ReactMarkdown>
      );
    },
    [datasource, isEditing]
  );

  const renderContent = () => {
    const renderInput = (
      inputProps: RenderInputProps,
      controllerProps: ControllerRenderProps<FieldValues, string>
    ): ReactNode => {
      if (
        !isEditing &&
        config.placeholder &&
        isDynamicVariable(config.placeholder.replace(/\s/g, ""))
      ) {
        config.placeholder = parseHandlebars(config.placeholder, datasource);
      }

      switch (config.inputType) {
        case "text":
        case "number":
          return (
            <TextInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              type="text"
            />
          );
        case "email":
          return (
            <TextInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              type={config.inputType}
            />
          );
        case "url":
          return (
            <UrlInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
            />
          );

        case "multi-url":
          return (
            <UrlInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
            />
          );

        case "textarea":
          return (
            <TextArea
              {...inputProps}
              {...controllerProps}
              rows={3}
              placeholder={config.placeholder}
              name={name}
            />
          );

        case "phone-number":
          return (
            <PhoneNumberInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              defaultCountry={config.defaultCountry as CountryCode}
            />
          );

        case "dropdown":
          // make separate DynamicDropdown component that calls Tables API and conditionally render it if this won't be a static dropdown
          // otherwise use static one as below
          if (config.tableId && config.tableFieldId) {
            // return dynamic dropdown
            return (
              <DynamicDropdownSelect
                {...inputProps}
                {...controllerProps}
                placeholder={config.placeholder}
                tableId={config.tableId}
                tableFieldId={Number(config.tableFieldId)}
                tablesFieldKey={config.tablesFieldKey}
                name={name}
                multiSelect={config.multiSelect}
              />
            );
          }
          return (
            <DropdownSelect
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              options={getDropdownOptions(config.options)}
              name={name}
              multiSelect={config.multiSelect}
            />
          );

        case "yes-no":
          return <YesNoInput {...inputProps} {...controllerProps} />;

        case "date-picker":
          return (
            <DatePicker
              {...inputProps}
              {...controllerProps}
              name={name}
              dateFormat={config.dateFormat ?? DateFormatOptions[0].value}
              placeholder={config.placeholder}
              includeTime={config.includeTime}
              defaultToNow={config.defaultToNow}
            />
          );

        case "checkbox":
          return (
            <Checkbox
              {...inputProps}
              {...controllerProps}
              label={config.label}
              name={name}
              required={config.required}
              defaultChecked={config.defaultChecked}
              text={config.text}
            />
          );

        case "currency": {
          return (
            <CurrencyInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              currencyFormat={config.currencyFormat as SupportedCurrencies}
            />
          );
        }

        case "file-upload":
          let allowedFileSize = FREE_FILE_SIZE;

          // This ensures that we never give away more upload size than allowed by the plan
          if (config.allowedFileSize) {
            allowedFileSize = Math.min(config.allowedFileSize, maxFileSize);
          }

          return (
            <FileUpload
              {...inputProps}
              {...controllerProps}
              label={config.label}
              name={name}
              required={config.required}
              placeholder={config.placeholder}
              allowedFileTypes={config.allowedFileTypes}
              maxFileSize={allowedFileSize}
              blockId={props.block.config.id ?? ""}
              isEditing={isEditing}
            />
          );

        case "ai-formula":
          return <AIFormula value={controllerProps.value?.value || ""} />;

        case "multiple-linked-record":
          return <LinkedRecordFields value={controllerProps.value} />;

        case "linked-record":
          return <LinkedRecordFields value={controllerProps.value} />;

        default:
          const _exhaustiveCheck: never = config;
          return _exhaustiveCheck;
      }
    };

    const customLabelRenderer = renderCustomLabel({
      block: props.block,
      isEditing,
    });

    return (
      <Suspense fallback={<Spinner height={20} />}>
        <Controller
          key={config.id}
          name={name}
          rules={validationRules}
          render={({ field: controllerProps }) => (
            <Field
              inputId={config.name}
              label={config.label}
              renderLabel={customLabelRenderer}
              isDisabled={isSubmitting}
              isRequired={config.required}
              renderHelpText={
                config.helpText
                  ? () => renderMarkdown(config.helpText ?? "")
                  : undefined
              }
              error={errors?.[name]?.message as string | undefined}
              renderInput={(inputProps) => {
                return renderInput(inputProps, controllerProps);
              }}
              hideLabel={!config.label}
            />
          )}
        />
      </Suspense>
    );
  };

  if (config.hidden) {
    return null;
  }

  return (
    <BlockWrapper blockId={props.blockId} block={props.block}>
      {renderContent()}
    </BlockWrapper>
  );
}

export function getDropdownOptions(options?: string) {
  return (
    options
      ?.split("\n")
      .map(trim)
      .filter((option) => !!option)
      .map((option) => ({
        label: option,
        value: option,
      })) ?? []
  );
}

const renderCustomLabel = ({
  isEditing,
  block,
}: {
  isEditing: boolean;
  block: FieldBlockType;
}): FieldProps["renderLabel"] => {
  if (isEditing && isConditionallyRendered(block)) {
    return function Component(props) {
      return (
        <ConditionallyRenderedLabel {...props} label={block.config.label} />
      );
    };
  }

  if (block.config.inputType === "ai-formula") {
    return function Component(props) {
      return <ReadOnlyLabel {...props} label={block.config.label} />;
    };
  }

  if (block.config.inputType === "multiple-linked-record") {
    return function Component(props) {
      return <ReadOnlyLabel {...props} label={block.config.label} />;
    };
  }

  if (block.config.inputType === "linked-record") {
    return function Component(props) {
      return <ReadOnlyLabel {...props} label={block.config.label} />;
    };
  }

  return undefined;
};
