import {
  FileInfo,
  FileUpload as FileUploadType,
  FilesUpload as FilesUploadType,
  Widget,
  WidgetAPI,
} from "@uploadcare/react-widget";
import {
  forwardRef,
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from "react";
import { config } from "@/config";
import {
  usePollingUploadStatusQuery,
  useUploadSignatureQuery,
} from "@/lib/uploads/hooks";
import { usePreviousCallback } from "@/lib/hooks";
import {
  DISALLOWED_MIME_TYPES,
  FILE_EXTENSIONS_WHITELIST,
} from "@/server/services/uploads/FILE_UPLOAD_WHITELIST";
import { allowedFileTypesConfigToArray } from "@/block-system/blocks/Form/Field/Editor/EditorFields/FileUploadFields";
import { useSplitFlags } from "@/lib/context/split-context";
import { useFormState } from "react-hook-form";
import { UploadSignatureOutput, UploadStatusOutput } from "@/utils/trpc";
import styles from "./FileUpload.styles.module.css";
import {
  AlertCircle,
  CloudUpload,
  FileImage,
  FileText,
  X as CloseIcon,
} from "lucide-react";
import { cn } from "@/block-system/brickz/lib/utils";

const Wrapper: React.FunctionComponent<React.ComponentPropsWithRef<"div">> = (
  props
) => {
  return (
    <div
      {...props}
      className={cn(
        "flex h-20 items-center justify-between bg-input-field",
        "rounded-md border border-dashed border-input",
        "px-3 py-2.5",
        "focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background",
        styles.container,
        props.className
      )}
    />
  );
};

const Placeholder: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div">
> = (props) => {
  return (
    <div
      {...props}
      className={cn(
        "flex shrink items-center gap-2",
        "text-sm text-input-field-foreground/60",
        "placeholder",
        props.className
      )}
    />
  );
};

type Props = {
  placeholder?: string;
  value?: FileInfo | null;
  allowedFileTypes?: string;
  onChange: (value?: FileInfo) => void;
  maxFileSize?: number;
  blockId: string;
  isEditing?: boolean;
  id: string;
  name: string;
  required: boolean;
  fileUploadsDisabled: boolean;
  uploadSignature: UploadSignatureOutput | undefined;
  uploadStatus: UploadStatusOutput | undefined;
  isUploadStatusError: boolean;
  file: FileInfo | undefined;
  fileSelected: boolean;
  onFileSelect?: (fileInfo: FileUploadType | FilesUploadType | null) => void;
  reset: () => void;
  acceptTypes: string;
  widgetRef: React.RefObject<WidgetAPI>;
  fileExtensionValidator: (fileInfo: FileInfo) => void;
  fileSizeValidator: (fileInfo: FileInfo) => void;
};

type FileUploadWidgetProps = Omit<
  Props,
  "name" | "maxFileSize" | "blockId" | "onChange"
>;

const FileUploadWidget = forwardRef<HTMLDivElement, FileUploadWidgetProps>(
  (props, ref) => {
    const {
      placeholder,
      fileUploadsDisabled,
      uploadSignature,
      uploadStatus,
      isUploadStatusError,
      file,
      fileSelected,
      onFileSelect,
      reset,
      acceptTypes,
      widgetRef,
      value,
      fileExtensionValidator,
      fileSizeValidator,
      ...restProps
    } = props;

    if (fileUploadsDisabled) {
      return (
        <div className={cn("flex gap-2 rounded-md bg-background p-4")}>
          <div className={cn("flex-none text-primary")}>
            <AlertCircle size={24} />
          </div>
          <div className="flex-1">
            File uploads are temporarily disabled and will return shortly. We
            apologize for the inconvenience.
          </div>
        </div>
      );
    }

    if (!uploadSignature && !restProps.isEditing) {
      return (
        <Wrapper>
          <Placeholder>
            <CloudUpload size={16} />
            {placeholder || "Drag and drop files here"}
          </Placeholder>
        </Wrapper>
      );
    }

    const renderFileIcon = () => {
      switch (file?.mimeType) {
        case "image/jpg":
        case "image/jpeg":
          return <FileImage size={16} />;
        default:
          return <FileText size={16} />;
      }
    };

    return (
      <Wrapper ref={ref}>
        <div
          className={cn("flex items-center justify-between", {
            "grow-0": !!file,
            grow: !file,
          })}
        >
          <Placeholder>
            {!fileSelected && (
              <>
                <CloudUpload size={16} />
                {placeholder || "Drag and drop files here"}
              </>
            )}

            {file ? renderFileIcon() : null}
          </Placeholder>

          {file && uploadStatus?.status === "pending" ? (
            <Placeholder>Verifying...</Placeholder>
          ) : null}
          {file &&
          (uploadStatus?.status === "failure" || isUploadStatusError) ? (
            <Placeholder>Failed to upload.</Placeholder>
          ) : null}
          {Boolean(!file || uploadStatus?.status === "success") && (
            <Widget
              ref={widgetRef}
              publicKey={config().NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY}
              secureSignature={uploadSignature?.secureSignature}
              secureExpire={
                uploadSignature?.secureExpire
                  ? parseInt(uploadSignature?.secureExpire)
                  : undefined
              }
              metadata={uploadSignature?.metadata}
              onFileSelect={onFileSelect}
              tabs="file camera url facebook gdrive gphotos"
              localeTranslations={{
                buttons: { choose: { files: { one: "Browse files" } } },
                errors: {
                  // @ts-ignore
                  fileType: "File type not supported",
                  fileMaximumSize: "File size exceeds max",
                },
                serverErrors: {
                  SignatureExpirationError:
                    "Upload failed. Please refresh your browser and retry.",
                },
              }}
              value={value?.cdnUrl ?? ""}
              inputAcceptTypes={acceptTypes}
              validators={[fileExtensionValidator, fileSizeValidator]}
            />
          )}
        </div>

        {file ? (
          <CloseIcon size={16} onClick={reset} style={{ cursor: "pointer" }} />
        ) : null}
      </Wrapper>
    );
  }
);

type FileUploadProps = {
  placeholder?: string;
  value?: FileInfo | null;
  allowedFileTypes?: string;
  onChange: (value?: FileInfo) => void;
  maxFileSize?: number;
  blockId: string;
  isEditing?: boolean;
  id: string;
  name: string;
  required: boolean;
};

const FileUpload = forwardRef<HTMLDivElement, FileUploadProps>((props, ref) => {
  const [fileUploadsDisabled] = useSplitFlags([
    "temporarily-disable-file-uploads",
  ]);

  const widgetRef = useRef<WidgetAPI>(null);

  const [file, setFile] = useState<FileInfo | undefined>(undefined);
  const [fileSelected, setFileSelected] = useState(false);

  const { isSubmitSuccessful } = useFormState();

  useEffect(() => {
    if (isSubmitSuccessful && file) {
      setFile(undefined);
    }
  }, [file, isSubmitSuccessful]);

  const { data: uploadSignature, refetch: refetchUploadSignature } =
    useUploadSignatureQuery(
      {
        type: "formSubmission",
        blockId: props.blockId,
      },
      {
        enabled: !props.isEditing,
      }
    );

  const { data: uploadStatus, isError: isUploadStatusError } =
    usePollingUploadStatusQuery(file?.uuid);

  usePreviousCallback(uploadStatus, (prev, current) => {
    if (prev?.status !== "success" && current?.status === "success" && file) {
      const reformattedFile: FileInfo = {
        ...file,
        cdnUrl: current.url,
      };

      props.onChange(reformattedFile);
    }
  });

  const acceptTypes = useMemo(() => {
    const defaultAllowList = FILE_EXTENSIONS_WHITELIST.map(
      (ext) => `.${ext}`
    ).join(",");
    if (!props.allowedFileTypes) return defaultAllowList;
    const allowedFileTypes = allowedFileTypesConfigToArray(
      props.allowedFileTypes
    ).filter((ext) => ext !== "*");

    const mimeTypes = allowedFileTypes
      .filter((ext) => FILE_EXTENSIONS_WHITELIST.includes(ext))
      .map((ext) => `.${ext}`);

    return mimeTypes.length ? mimeTypes.join(",") : defaultAllowList;
  }, [props.allowedFileTypes]);

  const fileSizeValidator = useCallback(
    (fileInfo: FileInfo) => {
      if (
        !props.maxFileSize ||
        fileInfo.size === null ||
        fileInfo.sourceInfo?.source === "uploaded"
      ) {
        return;
      }

      if (fileInfo.size > props.maxFileSize) {
        throw new Error("fileMaximumSize");
      }
    },
    [props.maxFileSize]
  );

  const fileExtensionValidator = useCallback(
    (fileInfo: FileInfo) =>
      validateFile({ fileInfo, allowedFileTypes: props.allowedFileTypes }),
    [props.allowedFileTypes]
  );

  const onFileSelect = useCallback(
    (e: any) => {
      setFileSelected(e !== null);
      if (e !== null) {
        e.done((file: FileInfo) => {
          setFile(file);
        });
      } else {
        props.onChange(undefined);
      }
    },
    [props]
  );

  const reset = useCallback(() => {
    setFileSelected(false);
    onFileSelect(null);
    setFile(undefined);
    void refetchUploadSignature();
  }, [onFileSelect, refetchUploadSignature]);

  return (
    <FileUploadWidget
      {...props}
      ref={ref}
      file={file}
      fileSelected={fileSelected}
      widgetRef={widgetRef}
      uploadSignature={uploadSignature}
      uploadStatus={uploadStatus}
      isUploadStatusError={isUploadStatusError}
      acceptTypes={acceptTypes}
      fileSizeValidator={fileSizeValidator}
      fileExtensionValidator={fileExtensionValidator}
      onFileSelect={onFileSelect}
      reset={reset}
      fileUploadsDisabled={fileUploadsDisabled}
    />
  );
});

export function validateFile({
  fileInfo,
  allowedFileTypes,
}: {
  fileInfo: FileInfo;
  allowedFileTypes: Props["allowedFileTypes"];
}) {
  if (fileInfo.sourceInfo?.source === "uploaded") {
    return;
  }

  const ext = fileInfo.name?.split(".").pop()?.toLowerCase();
  const isDisabledMimeType = DISALLOWED_MIME_TYPES.includes(
    fileInfo.mimeType ?? ""
  );

  const computedAllowedFileTypes = allowedFileTypes
    ? allowedFileTypesConfigToArray(allowedFileTypes).filter(
        (ext) => ext !== "*"
      )
    : FILE_EXTENSIONS_WHITELIST;

  if (!ext || !computedAllowedFileTypes.includes(ext) || isDisabledMimeType) {
    throw new Error("fileType");
  }
}

FileUploadWidget.displayName = "FileUploadWidget";
FileUpload.displayName = "FileUpload";

export { FileUpload, FileUploadWidget };
