import { ProjectAuthType } from "@prisma/client";
import { SSR_STALE_TIME } from "lib/constants";
import { useCurrentProject } from "lib/context/current-project-context";
import { usePageContext } from "lib/context/page-context";
import { trpc } from "utils/trpc";

export function useConsumer() {
  const { isEditing, isThumbnail } = usePageContext();
  const project = useCurrentProject();

  const isValidAuthType =
    project.authType === ProjectAuthType.Consumer ||
    project.authType === ProjectAuthType.Stytch;

  const { data: consumer } = trpc.consumers.me.useQuery(
    { projectId: project.id },
    {
      staleTime: SSR_STALE_TIME,
      enabled: isValidAuthType && !isEditing && !isThumbnail,
    }
  );

  return consumer;
}
