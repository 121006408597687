import { useShadcnComponentsEnabled } from "@/lib/theme/ThemeProvider";
import { truncate } from "lodash";
import { cn } from "utils/cn";
import { AIFormula as ShadcnAIFormula } from "@/block-system/brickz/components/ui/AIFormula";

type LegacyAIFormulaProps = {
  value: string;
  className?: string;
};

const LegacyAIFormula: React.FunctionComponent<LegacyAIFormulaProps> = ({
  value,
  className,
}) => (
  <div
    className={cn(
      "color-container min-h-28 rounded border bg-gray-50 px-3 py-4",
      className
    )}
  >
    {truncate(value, { length: 250 })}
  </div>
);

export const AIFormula = (props: LegacyAIFormulaProps) => {
  const shadcnComponentsEnabled = useShadcnComponentsEnabled();
  if (shadcnComponentsEnabled) {
    return <ShadcnAIFormula {...props} />;
  }

  return <LegacyAIFormula {...props} />;
};
