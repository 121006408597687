import { BlockCreateProps } from "block-system/blocks/__shared__/types";
import { LinksCardBlock } from "../LinksCard/types";
import { blockType } from "./constants";
import { LinksBlockSchema } from "./schema";
import { Links, LinksBlock } from "./types";
import {
  create as createLinkCard,
  copy as copyLinksCardBlock,
  map as mapLinkCard,
} from "../LinksCard/operations";

export function copy(block: LinksBlock): LinksBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
    children: block.children.map<LinksCardBlock>((child) =>
      copyLinksCardBlock(child)
    ),
  };
}

export const create: Links["create"] = (
  props?: BlockCreateProps<LinksBlock>
) => {
  return LinksBlockSchema.parse({
    type: blockType,
    config: {
      ...(props?.initialConfig ?? {}),
      headerTitle: props?.initialConfig?.headerTitle ?? "Links",
      style: {
        ...props?.initialConfig?.style,
        width: props?.initialConfig?.style?.width ?? "medium",
        alignment: props?.initialConfig?.style?.alignment ?? "center",
      },
    },
    children: props?.children?.length ? props.children : [createLinkCard()],
  });
};

export const map: Links["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...LinksBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
    children: (block.children ?? []).map(mapLinkCard),
  };
};
