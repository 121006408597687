import { useState, useEffect } from "react";
import { trpc } from "utils/trpc";
import { UPLOAD_SIGNATURE_LIFETIME_MS } from "./constants";

// Upload Signature Query
// -----------------------------------------------------------------------------

export function useUploadSignatureQuery(
  args: Parameters<typeof trpc.uploads.getSignature.useQuery>[0],
  // NOTE: I wanted to allow all the options from `useQuery` to be passed in
  // like this:
  //   options?: Parameters<typeof trpc.uploads.getSignature.useQuery>[1]
  // But for some reason that makes the return value `unkown`.
  options?: { enabled?: boolean }
) {
  return trpc.uploads.getSignature.useQuery(args, {
    staleTime: UPLOAD_SIGNATURE_LIFETIME_MS / 2,
    refetchInterval: UPLOAD_SIGNATURE_LIFETIME_MS / 2,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    throwOnError: false,
    ...options,
  });
}

// Upload Status Query
// -----------------------------------------------------------------------------

export function usePollingUploadStatusQuery(uuid: string | undefined | null) {
  const query = trpc.uploads.getStatus.useQuery(
    { uuid: uuid as Exclude<typeof uuid, undefined | null> },
    {
      enabled: !!uuid,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      throwOnError: false,
    }
  );

  const [refetchCount, setRefetchCount] = useState(0);

  // Reset the refetch count when the uuid changes. This indicates that the user
  // is uploading a new file.
  useEffect(() => {
    if (uuid) {
      setRefetchCount(0);
    }
  }, [uuid]);

  // Poll the upload status if the upload is pending.
  useEffect(() => {
    if (query.isSuccess && query.data?.status === "pending") {
      const nextRefetch = getNextRefetch(refetchCount);

      if (nextRefetch === null) return;

      const timeout = setTimeout(() => {
        void query
          .refetch()
          .then(() => {
            setRefetchCount((prev) => prev + 1);
          })
          .catch(() => {
            setRefetchCount((prev) => prev + 1);
          });
      }, nextRefetch);

      return () => clearTimeout(timeout);
    }
  }, [query, query.data?.status, query.isSuccess, refetchCount]);

  return query;
}

// Exponential backoff constants. These meet the following criteria:
// - The first interval is 100ms.
// - Tries approximately 20 times in 1 minute.
const INITIAL_INTERVAL_MS = 100;
const BACKOFF_FACTOR = 1.258925412;
const MAX_TRIES = 20;

// Calculate the exponential backoff for polling the upload status.
export function getNextRefetch(count: number): number | null {
  // Stop polling after the max number of tries.
  if (count > MAX_TRIES) return null;

  // Calculate the exponential backoff.
  const calculatedInterval =
    INITIAL_INTERVAL_MS * Math.pow(BACKOFF_FACTOR, count);

  // Add a random jitter of up to 25%, but no more than 1 second.
  const randomJitter = Math.min(1000, calculatedInterval / 4) * Math.random();

  // Return the calculated interval with the random jitter, rounded down.
  return Math.floor(calculatedInterval + randomJitter);
}
