import type { SupportedCurrencies } from "block-system/blocks/Form/Field/types";
import { renderCurrencyIcon } from "block-system/utilities/currency-helpers";
import { CurrencyInput as ShadcnCurrencyInput } from "@/block-system/brickz/components/ui/CurrencyInput";
import { forwardRef } from "react";
import { TextInput, Props as TextInputProps } from "./TextInput";
import { useShadcnComponentsEnabled } from "@/lib/theme/ThemeProvider";

type BaseProps = Omit<
  TextInputProps,
  "renderIconBefore" | "inputMode" | "type"
>;

type CurrencyInputProps = BaseProps & {
  currencyFormat: SupportedCurrencies;
};

const LegacyCurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ currencyFormat, ...props }, ref) => {
    return (
      <TextInput
        {...props}
        ref={ref}
        renderIconBefore={() => renderCurrencyIcon(currencyFormat)}
        type="text"
      />
    );
  }
);

LegacyCurrencyInput.displayName = "LegacyCurrencyInput";

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (props, ref) => {
    const isShadcnEnabled = useShadcnComponentsEnabled();
    if (isShadcnEnabled) {
      return <ShadcnCurrencyInput {...props} ref={ref} />;
    }

    return <LegacyCurrencyInput {...props} ref={ref} />;
  }
);

CurrencyInput.displayName = "CurrencyInput";
