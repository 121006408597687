import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

import { cn } from "@/block-system/brickz/lib/utils";
import { forwardRef, useId } from "react";
import { Label } from "./Label";

const Checkbox = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer h-3.5 w-3.5 shrink-0 rounded-[2px] border border-solid border-input focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <Check className="h-3.5 w-3.5" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

type BaseProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  "onChange" | "value"
>;

export type CheckboxInputProps = BaseProps & {
  value?: boolean;
  label: string;
  text?: string;
  defaultChecked: boolean;
  onChange?: (value: boolean) => void;
  name: string;
  isErrored?: boolean;
};

const CheckboxInput = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxInputProps
>(
  (
    { value, onChange, text, label, defaultChecked, isErrored, ...props },
    ref
  ) => {
    const id = useId();

    const handleChange = (checked: boolean) => {
      return onChange?.(checked);
    };

    return (
      <div
        className={cn("flex gap-2", {
          "items-top": !!label,
          "items-center": !label,
        })}
      >
        <Checkbox
          id={id}
          ref={ref}
          {...props}
          checked={value ?? defaultChecked}
          onCheckedChange={handleChange}
          data-errored={isErrored}
          className="data-[errored=true]:border-destructive"
        />
        <div className="text-3.5 grid gap-1.5 leading-none text-card-foreground">
          <Label
            htmlFor={id}
            data-errored={isErrored}
            className="data-[errored=true]:text-destructive"
          >
            {label}
          </Label>
          {text ? <p className="text-card-foreground-subtle">{text}</p> : null}
        </div>
      </div>
    );
  }
);

CheckboxInput.displayName = "CheckboxInput";

export { CheckboxInput };
