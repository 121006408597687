import { MouseEventHandler } from "react";
import Link from "next/link";
import { AnchorTarget } from "@zapier/design-system";
import { cn } from "utils/cn";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

type Props = {
  title: string;
  description?: string;
  linkText: string;
  emoji?: string;
  href: string;
  target?: AnchorTarget;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
};

export function LinksCard({
  title,
  description,
  linkText,
  emoji,
  href,
  target,
  onClick,
}: Props) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <div
      className={cn("flex min-h-[180px] flex-col shadow-md", {
        "rounded-xl bg-zi-white": !interfacesTheme,
        "rounded-large bg-card": interfacesTheme,
      })}
    >
      <div className="flex flex-1 flex-row gap-5 p-5">
        {emoji ? (
          <div
            className={cn([
              "h-12 w-12 border",
              "flex items-center justify-center",
              "text-[26px]",
              {
                "rounded-lg bg-zi-white": !interfacesTheme,
                "rounded-small bg-background": interfacesTheme,
              },
            ])}
          >
            {emoji}
          </div>
        ) : null}
        <div className="flex flex-1 flex-col gap-2">
          <div
            className={cn([
              "font-sans text-lg font-semibold",
              {
                "text-card-foreground": interfacesTheme,
              },
            ])}
          >
            {title}
          </div>
          {description ? (
            <div
              className={cn([
                "font-sans text-sm leading-[1.6]",
                {
                  "text-zi-secondaryText": !interfacesTheme,
                  "text-card-foreground-subtle": interfacesTheme,
                },
              ])}
            >
              {description}
            </div>
          ) : null}
        </div>
      </div>
      <Link
        href={href}
        target={target}
        onClick={onClick}
        className={cn([
          "flex items-center justify-center px-4 py-5 text-[17px] font-medium leading-5 transition-colors",
          {
            "rounded-b-xl bg-zi-primary-50 text-zi-primary-600 hover:bg-zi-primary-100":
              !interfacesTheme,
            "rounded-b-large bg-secondary text-secondary-foreground [&:not(:disabled):hover]:brightness-95":
              interfacesTheme,
          },
        ])}
      >
        <span>{linkText} -&gt;</span>
      </Link>
    </div>
  );
}
