import {
  useInterfacesTheme,
  useShadcnComponentsEnabled,
} from "lib/theme/ThemeProvider";
import { CountryCode } from "libphonenumber-js/types";
import { forwardRef } from "react";
import PhoneInput, { Value } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { cn } from "utils/cn";
import { PhoneNumberInput as ShadcnPhoneNumberInput } from "@/block-system/brickz/components/ui/PhoneNumberInput";

export type Props = {
  value?: string;
  onChange?: (value: Value | undefined) => void;
  defaultCountry: CountryCode;
  placeholder?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  id: string;
  name: string;
};

const LegacyPhoneNumberInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      onChange,
      value,
      defaultCountry,
      placeholder,
      isDisabled,
      isErrored,
      id,
      name,
    },
    ref
  ) => {
    return (
      <LegacyWrapper $isErrored={isErrored} ref={ref}>
        <PhoneInput
          flags={flags}
          placeholder={placeholder}
          defaultCountry={defaultCountry}
          value={value}
          numberInputProps={{ id, name }}
          onChange={(value) => {
            onChange?.(value);
          }}
          disabled={isDisabled}
        />
      </LegacyWrapper>
    );
  }
);

LegacyPhoneNumberInput.displayName = "LegacyPhoneNumberInput";

const LegacyWrapper: React.FunctionComponent<
  React.ComponentPropsWithRef<"div"> & {
    $isErrored?: boolean;
  }
> = ({ $isErrored, ...props }) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "custom-phoneinput flex h-[50px] flex-row flex-nowrap items-center gap-x-2.5 overflow-hidden border-2 px-[15px] py-0 text-[17px] transition-all duration-150",
        {
          "interfaces-theme rounded-large border-input bg-input-field text-input-field-foreground focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background":
            interfacesTheme,
          "rounded-lg border-zi-lightGray bg-zi-superLightGray text-zi-text focus-within:border-zi-primary":
            !interfacesTheme,
          "border-destructive": interfacesTheme && $isErrored,
          "border-zi-formError": !interfacesTheme && $isErrored,
        },
        props.className
      )}
    />
  );
};

export const PhoneNumberInput = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const shadcnComponentsEnabled = useShadcnComponentsEnabled();
    if (shadcnComponentsEnabled) {
      return <ShadcnPhoneNumberInput {...props} ref={ref} />;
    }

    return <LegacyPhoneNumberInput {...props} ref={ref} />;
  }
);

PhoneNumberInput.displayName = "PhoneNumberInput";
