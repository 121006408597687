import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type FunctionComponent,
  type ReactNode,
} from "react";

import { cn } from "@/block-system/brickz/lib/utils";

type InputProps = Omit<
  React.ComponentPropsWithoutRef<"input">,
  "onChange" | "size" | "type" | "value"
>;

export type TextInputProps = InputProps & {
  value?: string;
  onChange?: (value: string) => void;
  type?: "email" | "password" | "search" | "tel" | "text" | "time";
  size?: "small" | "medium";
  isErrored?: boolean;
  renderIconBefore?: () => ReactNode;
  renderIconAfter?: () => ReactNode;

  /**
   * These props are superfluous here.
   * We have them defined in `packages/service/src/block-system/components/forms/Field.tsx` but we never used them.
   * They caused React errors related to unknown props.
   *
   * I'm deferring the refactor for now.
   */
  isDisabled?: boolean;
  isRequired?: boolean;
  ariaDescribedBy?: string;
  containerClassName?: string;
  active?: boolean;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      onChange,
      isErrored,
      size,
      isDisabled = false,
      disabled = false,
      isRequired = false,
      required = false,
      ariaDescribedBy,
      renderIconAfter,
      renderIconBefore,
      type = "text",
      className,
      containerClassName,
      active = false,
      ...props
    },
    ref
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
      event
    ) => {
      onChange?.(event.currentTarget.value);
    };

    const inputDisabled = disabled || isDisabled;
    const inputRequired = required || isRequired;

    return (
      <div
        className={cn(
          "flex flex-row flex-nowrap gap-x-2.5",
          "border-1 border border-input",
          "px-3 py-2.5",
          "text-sm",
          "transition-colors duration-150",
          "h-10 min-h-10",
          "overflow-hidden",
          "rounded-medium",
          "bg-input-field",
          "text-input-field-foreground",
          "focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background",
          {
            "h-9 min-h-9 py-1.5 text-xs": size === "small",
            "outline-none ring-2 ring-ring ring-offset-2 ring-offset-background":
              active == true,
            "border-destructive": isErrored,
            "cursor-not-allowed opacity-50 [&_*]:cursor-not-allowed":
              inputDisabled,
          },
          containerClassName
        )}
      >
        {renderIconBefore ? (
          <IconWrapper>{renderIconBefore()}</IconWrapper>
        ) : null}
        <input
          type={type}
          data-size={size}
          className={cn(
            "block",
            "h-full",
            "border-none",
            "bg-transparent",
            "text-inherit",
            "outline-none",
            "placeholder:text-input-field-foreground/60",
            "w-0",
            "grow",
            className
          )}
          ref={ref}
          aria-describedby={ariaDescribedBy}
          onChange={onChange != null ? handleChange : undefined}
          required={inputRequired}
          disabled={inputDisabled}
          value={props.value ?? ""}
          {...props}
        />
        {renderIconAfter ? (
          <IconWrapper>{renderIconAfter()}</IconWrapper>
        ) : null}
      </div>
    );
  }
);

const IconWrapper: FunctionComponent<ComponentPropsWithoutRef<"div">> = (
  props
) => {
  return (
    <div
      {...props}
      className={cn(
        "flex h-full grow-0 items-center justify-center",
        "text-input-field-foreground/70"
      )}
    />
  );
};

TextInput.displayName = "Input";

export { TextInput };
